<template>
  <div justify="end">
    <v-dialog
      v-model="isVisibleDialog"
      width="60%"
      max-width="1200px"
      persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          class="mx-5"
          v-bind="attrs"
          v-on="on"
        >
          {{ $t("import_csv") }}
        </v-btn>
      </template>
      <v-progress-linear
        v-if="showProgress"
        v-model="progress"
        :active="showProgress"
        :color="importStatus.error === true ? 'error' : 'success'"
        height="40"
      >
        <strong color="white">{{ progressText }}</strong>
      </v-progress-linear>
      <v-form
        ref="form"
        v-model="is_valid"
        lazy-validation
      >
        <v-card>
          <v-card-title class="pb-4">
            <span class="text-h3">{{ $t("products csv") }}</span>
          </v-card-title>
          <v-card-text class="pa-0 grey lighten-4">
            <v-container>
              <v-card>
                <v-card-text>
                  <v-alert
                    v-if="importStatus.error === true"
                    text
                    prominent
                    type="error"
                    icon="mdi-cloud-alert"
                  >
                    <v-row align="center">
                      <v-col class="grow">
                        {{ $t("import_products_error") }},<br />
                        {{ importStatus.error_count }}
                        {{ $t("items_not_updated") }}
                      </v-col>
                      <v-col class="shrink">
                        <v-btn
                          color="primary"
                          text
                          @click="getImportFailedResult()"
                          :loading="is_loading_csv"
                          >{{ $t("download") }}</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-alert>
                  <v-alert
                    v-if="
                      importStatus.error === false &&
                      importStatus.finished === true
                    "
                    text
                    prominent
                    type="success"
                  >
                    <v-row align="center">
                      <v-col class="grow">
                        {{ $t("import_products_success") }}
                      </v-col>
                    </v-row>
                  </v-alert>
                  <v-row>
                    <v-col cols="12">
                      <v-file-input
                        dense
                        outlined
                        @change="onFileChanged($event)"
                        :key="fileInputKey"
                        type="file"
                        label="csv"
                        prepend-icon=""
                        append-icon="mdi-file"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      ></v-file-input>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="success"
              text
              @click="getImportTemplate()"
              :loading="is_loading_csv"
            >
              {{ $t("download_excel_template") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              text
              @click="closeForm()"
            >
              {{ $t("close") }}
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="uploadCSV(products)"
              :disabled="!is_valid"
              :loading="is_uploading"
            >
              {{ $t("upload") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { validationRules } from "@/mixins/validationRules";
import { mapGetters } from "vuex";
// import debounce from "lodash";

export default {
  mixins: [validationRules],
  computed: {
    ...mapGetters({
      importStatus: "products/importStatus",
    }),
    progressText: function () {
      if (this.importStatus.error === true) {
        return "Import failed";
      }
      if (this.progress == 100) return "100 %";
      return (
        this.progress.toString() +
        " %  " +
        this.importStatus.current_row.toString() +
        " Of " +
        this.importStatus.total_rows.toString() +
        " Imported"
      );
    },
  },
  // watch: {
  //   importing: debounce(function () {
  //     if (this.isLoadingProviders) return;
  //     this.$store.dispatch("products/importStatus");
  //   }, 1000),
  // },
  data() {
    // this.trackProgress = debounce(this.trackProgress,1000);
    return {
      products: {},
      is_valid: true,
      is_uploading: false,
      isVisibleDialog: false,
      is_loading_csv: false,
      importing: false,
      progress: 0,
      showProgress: false,
      fileInputKey: 0,
    };
  },
  methods: {
    closeForm() {
      this.$refs.form.reset();
      this.isVisibleDialog = false;
      (this.is_uploading = false), (this.products = {});
      this.showProgress = false;
      this.fileInputKey++;
      this.$store.dispatch("products/cleanImportStatus");
    },
    async uploadCSV(products) {
      if (this.$refs.form.validate()) {
        try {
          this.is_uploading = true;
          await this.$store.dispatch("products/uploadCSV", products);
          // console.log(this.importStatus,'fir');
          this.trackProgress();
          this.showProgress = true;
          this.importing = true;
          this.is_uploading = false;
          // this.closeForm();
        } catch (error) {
          console.log(error);
          this.$store.dispatch("alerts/error", error.response?.data?.message);
          this.is_uploading = false;
        }
        this.is_uploading = false;
      }
    },
    async getImportTemplate() {
      this.is_loading_csv = true;
      await this.$store
        .dispatch("products/downloadTemplate")
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "products_import.xlsx");
          document.body.appendChild(link);
          link.click();
          this.is_loading_csv = false;
        })
        .catch((error) => {
          this.is_loading_csv = false;
          this.$store.dispatch("alerts/error", error.response?.statusText);
          this.$store.dispatch("alerts/error", error.response?.data?.message);
        });
    },
    async getImportFailedResult() {
      this.is_loading_csv = true;
      await this.$store
        .dispatch("products/downloadFailedResult")
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "products_import.xlsx");
          document.body.appendChild(link);
          link.click();
          this.is_loading_csv = false;
        })
        .catch((error) => {
          this.is_loading_csv = false;
          this.$store.dispatch("alerts/error", error.response?.statusText);
          this.$store.dispatch("alerts/error", error.response?.data?.message);
        });
    },
    onFileChanged(e) {
      this.products.csv = e;
    },
    async trackProgress() {
      try {
        await this.$store.dispatch("products/importStatus");
        if (this.importStatus.finished) {
          this.progress = 100;
          this.importing = false;
          return;
        }
        this.progress = Math.ceil(
          (this.importStatus.current_row / this.importStatus.total_rows) * 100
        );
        await setTimeout(() => this.trackProgress(), 1000);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>